<template>

    <div>
      <v-container >
      <h2 >Wallet</h2>
      <p class="text-dark">Earnings can be aquired through invitations. Earn 10% or referral amount for every payment by your invite.</p>
      <v-card outlined class="mb-5" id="walletCard">
        <v-card-title class="border-bottom">
          <v-row class="" align="center">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search.wallet"
              label="Search :  Description | Date | Amount  "
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="">
            <p class="text-caption text-uppercase mb-0">EARNINGS</p>
            <p class="h5 mb-0 " >
                + {{ computations.total_addition | currency(true) }}
              </p>
          </v-col>
          <v-col cols="12" md="2" class="">
            <p class="text-caption text-uppercase mb-0">DEDUCTIONS</p>
            <p class="h5 mb-0" >
                - {{ computations.total_deduction | currency(true, true) }}
              </p>
          </v-col>
          <v-col cols="12" md="2" class="text-right">
            <p class="text-caption text-uppercase mb-0">BALANCE</p>
            <p class="h4 mb-0" >
                 {{ computations.total_wallet ? computations.total_wallet : 0 | currency(true)  }} 
              </p>
          </v-col>
        </v-row>
        </v-card-title>
        
        <v-data-table
          id="wallet"
          :headers="dataTable.wallet_headers"
          :items="arrays.wallets"
          :search="search.wallet"
          :loading="loading.wallet"
          loading-text="Loading... Please wait"
          :hide-default-footer="true"
          disable-sort
          
        >
        <template v-slot:[`item.description`]="{ item }">
          <span v-if="item.description"> {{ item.description }} </span>
          <span v-else>Not Available</span>
        </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span v-if="item.amount < 0" class='text-danger'>- {{ item.amount  | currency(true, true) }} </span>
            <span v-else class="bkack--text"> {{ item.amount  | currency(true, true) }} </span>
            
            
          </template>
          </v-data-table>
          <Pagination
            v-bind:meta="pagination.wallet.meta"
            @page="paginateWallet"
          >
          </Pagination>
        </v-card>
        <h2 >Invites</h2>
        <p class="text-dark">Your registered invites will have 30 days free trial instead of the usual 14 days trial.</p>
        <v-card outlined id="inviteCard">
          <v-card-title class="border-bottom">
            <v-row align="center">
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  label="Search : User | Company | Date"
                    hide-details
                  v-model="search.invite"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="text-right">
                <p class="text-caption text-uppercase mb-0">referral code</p>
                <p class="h4 mb-0">
                  <span v-if="arrays.user.referral_code"> {{ arrays.user.referral_code }} </span>
                  <span v-else> N/A </span>
                </p>
              </v-col>
            </v-row>
          </v-card-title>
          
          <v-data-table
            :headers="dataTable.invite_headers"
            :items="filterInvites"
            :loading="loading.invite"
            loading-text="Loading... Please wait"
            :hide-default-footer="true"
            disable-sort
          >
            <template v-slot:[`item.name`]="{ item }">
              <span class="text-capitalize">
                {{ item.user.first_name }} {{ item.user.last_name }}
              </span>
            </template>
            <template v-slot:[`item.company`]="{ item }" >
              <span v-if="item.company"> {{ item.company.name }}</span>
              <span v-else> Not Available </span> 
            </template>
          </v-data-table>
          <Pagination
            v-bind:meta="pagination.invites.meta"
            @page="paginateInvite"
          >
          </Pagination>
        </v-card>
      </v-container>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  props : {
    myInfo : Object
  },
  data: () => ({
    arrays: {
      wallets: [],
      invites: [],
      user: [],
      allWallets: [],
    },
    computations: {
      total_wallet: 0,
      total_addition: 0,
      total_deduction: 0,
    },
    dataTable: {
      wallet_headers: [
        { text: "Description", value: "remarks", class : 'black--text' },
        { text: "Date", value: "date_time_created", class : 'black--text' },
        { text: "Amount", value: "amount", class : 'black--text' },
      ],
      invite_headers: [
        { text: "Name", value: "name", class : 'black--text' },
        { text : 'Company', value: 'company', class : 'black--text'},
        { text: "Date Registered", value: "date_time_created", class : 'black--text' },
      ],
    },
    search: {
      wallet: "",
      invite: "",
    },
    loading: {
      wallet: true,
      invite: true,
    },
    form_user: {
      company_id: null,
    },
    status : {
      admin : false
    },
    pagination: {
      wallet: {
        current: null,
        meta: null,
      },
      invites: {
        current: null,
        meta: null,
      },
    },
  }),
  created() {
    if(this.myInfo)
    {
      this.arrays.user = this.myInfo
      this.getInit()
    }
  },
  computed: {
    filterInvites() {
      return this.arrays.invites ? this.arrays.invites.filter((invite) => {
        return (
          invite.user.first_name.match(this.search.invite) ||
          invite.user.last_name.match(this.search.invite) ||
          invite.company.name.match(this.search.invite) ||
          invite.date_time_created.match(this.search.invite) 
        );
      }) : [] ; 
    }
  },
  watch : {
    myInfo : function(val)
    {
      this.arrays.user = val
      this.getInit()
    }
  },
  methods: {
    getInit() {
      if(this.arrays.user)
      {
        this.form_user.company_id = this.arrays.user.default_user_company.company.id
        this.getWallet();
      }
    },
    paginateWallet(page) {
      this.pagination.wallet.current = page
      this.getWallet(page)
    },
    paginateInvite(page) {
      this.pagination.invites.current = page;
      this.getInvite(page);
    },
    getWallet(page) {
      this.loading.wallet = true
      let set_page = ''
      if(page)
      {
        set_page = page
      }
      this.axios
        .get("/accounting?wallet=true&user_id=" + this.arrays.user.id+'&page='+set_page)
        .then(({ data }) => {
          let d = data.accounting
          if(d && d.length > 0)
          {
            for (var i = 0; i != d.length; i++) {
      
              d[i].date_time_created = this.$options.filters.date(d[i].date_time_created, true)
            }
          }
          data.accounting = d
          this.arrays.wallets = data.accounting
          if(!set_page){
            this.pagination.wallet.meta = data.meta
          }
          if(set_page)
          {
            if(window.innerWidth < 600)
            {
              document.getElementById('walletCard').scrollIntoView()
            }
          }
          this.getAllWallet()
        })
        .finally(() => {
          if(!set_page)
          {
            this.getInvite();
          }
        });
    },
    getAllWallet() {
      this.axios
        .get(
          "/accounting?wallet=true&user_id=" + this.arrays.user.id + "&per_page=" + this.pagination.wallet.meta.total )
        .then(({ data }) => {
          
          this.arrays.allWallets = data.accounting
          this.compute(data.accounting)
          this.loading.wallet = false
        });
    },
    getInvite(page) {
      let set_page = "";
      if (page) {
        set_page = page;
      }

      this.loading.invite = true;
      this.axios
        .get(
          "/inviter?referral_code=" +
            this.arrays.user.referral_code +
            "&page=" +
            set_page,
          this.form_user
        )
        .then(({ data }) => {
          if (data) {
            data.inviter = this.formatDate(data.inviter);
            this.arrays.invites = data.inviter;
            if (!set_page) {
              this.pagination.invites.meta = data.meta;
            }
            this.loading.invite = false;

            if(set_page)
            {
              if(window.innerWidth < 600)
              {
                document.getElementById('inviteCard').scrollIntoView()
              }
            }
          }
        });
    },
    compute(data) {
      this.computations.total_wallet = 0
      this.computations.total_addition = 0
      this.computations.total_deduction = 0
      if(data && data.length > 0)
      {
        for (var i = 0; i != data.length; i++) {
          if (data[i].amount >= 0 ) {
            this.computations.total_addition += data[i].amount;
          }
          if (data[i].amount < 0) {
            this.computations.total_deduction += data[i].amount;
          }
        }
        this.computations.total_wallet = this.computations.total_addition + this.computations.total_deduction;
      }
    },
    deduction(number) {
      return number < 0 ? true : false;
    },
    formatDate: function (data) {
      if(data && data.length > 0)
      {
        for (var i = 0; i != data.length; i++) {
   
          data[i].date_time_created = this.$options.filters.displayDatev2(data[i].date_time_created, true)
        }
        return data;
      }
      
    },
  },
};
</script>
<style type="text/css" scope>
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
  margin-left: 1rem;
}
.v-data-table-header-mobile{
  display: none!important;
}
</style>