var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('h2',[_vm._v("Wallet")]),_c('p',{staticClass:"text-dark"},[_vm._v("Earnings can be aquired through invitations. Earn 10% or referral amount for every payment by your invite.")]),_c('v-card',{staticClass:"mb-5",attrs:{"outlined":"","id":"walletCard"}},[_c('v-card-title',{staticClass:"border-bottom"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Search :  Description | Date | Amount  ","hide-details":"","dense":""},model:{value:(_vm.search.wallet),callback:function ($$v) {_vm.$set(_vm.search, "wallet", $$v)},expression:"search.wallet"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('p',{staticClass:"text-caption text-uppercase mb-0"},[_vm._v("EARNINGS")]),_c('p',{staticClass:"h5 mb-0 "},[_vm._v(" + "+_vm._s(_vm._f("currency")(_vm.computations.total_addition,true))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('p',{staticClass:"text-caption text-uppercase mb-0"},[_vm._v("DEDUCTIONS")]),_c('p',{staticClass:"h5 mb-0"},[_vm._v(" - "+_vm._s(_vm._f("currency")(_vm.computations.total_deduction,true, true))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"2"}},[_c('p',{staticClass:"text-caption text-uppercase mb-0"},[_vm._v("BALANCE")]),_c('p',{staticClass:"h4 mb-0"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.computations.total_wallet ? _vm.computations.total_wallet : 0,true))+" ")])])],1)],1),_c('v-data-table',{attrs:{"id":"wallet","headers":_vm.dataTable.wallet_headers,"items":_vm.arrays.wallets,"search":_vm.search.wallet,"loading":_vm.loading.wallet,"loading-text":"Loading... Please wait","hide-default-footer":true,"disable-sort":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('span',[_vm._v(" "+_vm._s(item.description)+" ")]):_c('span',[_vm._v("Not Available")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount < 0)?_c('span',{staticClass:"text-danger"},[_vm._v("- "+_vm._s(_vm._f("currency")(item.amount,true, true))+" ")]):_c('span',{staticClass:"bkack--text"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,true, true))+" ")])]}}],null,true)}),_c('Pagination',{attrs:{"meta":_vm.pagination.wallet.meta},on:{"page":_vm.paginateWallet}})],1),_c('h2',[_vm._v("Invites")]),_c('p',{staticClass:"text-dark"},[_vm._v("Your registered invites will have 30 days free trial instead of the usual 14 days trial.")]),_c('v-card',{attrs:{"outlined":"","id":"inviteCard"}},[_c('v-card-title',{staticClass:"border-bottom"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Search : User | Company | Date","hide-details":""},model:{value:(_vm.search.invite),callback:function ($$v) {_vm.$set(_vm.search, "invite", $$v)},expression:"search.invite"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-caption text-uppercase mb-0"},[_vm._v("referral code")]),_c('p',{staticClass:"h4 mb-0"},[(_vm.arrays.user.referral_code)?_c('span',[_vm._v(" "+_vm._s(_vm.arrays.user.referral_code)+" ")]):_c('span',[_vm._v(" N/A ")])])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.dataTable.invite_headers,"items":_vm.filterInvites,"loading":_vm.loading.invite,"loading-text":"Loading... Please wait","hide-default-footer":true,"disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" ")])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('span',[_vm._v(" "+_vm._s(item.company.name))]):_c('span',[_vm._v(" Not Available ")])]}}],null,true)}),_c('Pagination',{attrs:{"meta":_vm.pagination.invites.meta},on:{"page":_vm.paginateInvite}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }